import $ from 'jquery';

export default function widthFunctions(e) {

	if($('.timeline')) {

		$('.timeslot').each(function(){

			var timeslotHeight = $(this).find('.task').outerHeight();

			$(this).css('height',timeslotHeight);

		});

	}

	var sidebarLeftHeight = $('#sidebar-left').outerHeight();
	var contentHeight = $('#content').height();
	var contentHeightOuter = $('#content').outerHeight();

    var winHeight = $(window).height();
    var winWidth = $(window).width();

	if (winWidth > 767) {

		if (sidebarLeftHeight > contentHeight) {

			$('#content').css("min-height",sidebarLeftHeight);

		} else {

			$('#content').css("min-height","auto");

		}

		$('#white-area').css('height',contentHeightOuter);

	} else {

		$('#white-area').css('height','auto');

	}


	if (winWidth < 768) {

		if($('.chat-full')) {

			$('.chat-full').each(function(){

				$(this).addClass('alt');

			});

		}

	} else {

		if($('.chat-full')) {

			$('.chat-full').each(function(){

				$(this).removeClass('alt');

			});

		}

	}

}