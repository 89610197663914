import 'angular';

export function SimpliqThemeMainMenuToggleDirective (
  $rootScope,
  gridAPI
) {
  return {
    restrict: 'A',
    scope: {},
    link (scope, element) {
      element.click(() => {
        if(element.hasClass('open')){
          $rootScope.sidebarOpen = true;

          element.removeClass('open').addClass('close');

          angular.element('#content').addClass('full');
          angular.element('#sidebar-left').hide();

        } else {
          $rootScope.sidebarOpen = false;

          element.removeClass('close').addClass('open');

          angular.element('#content').removeClass('full');
          angular.element('#sidebar-left').show();

        }

        if (angular.element('[ng-grid]').length) {
          const gridScope = angular.element('[ng-grid]').scope().$parent.grid;

          gridAPI.resizeGrid(gridScope);
        }
      });
    }
  }
}
